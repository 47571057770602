<template>
  <div class="animated fadeIn">

      <!-- THIS MODAL IS TO SEE THE MASTER INVOICE INVOICES -->
      <b-modal id="master-invoice-modal" ref="master-invoice-modal" class="modal-dialog" hide-header ok-only>
        <b-card>
          <!-- <rotate-square2 id="loading-gif" v-show="loadingInvoiceData"></rotate-square2> -->
          <div class="row">
            <div class="col-md-12">
              <Master-Invoice v-bind:propafes="this.afes" v-bind:propinvoice="this.invoice"> </Master-Invoice>
            </div>
          </div>
        </b-card>
      </b-modal>

    <b-card class="clearfix">
      <InvoiceHeader v-bind:invoice="invoice" v-bind:vwref="vwref" v-bind:serviceData="serviceData" v-bind:afes="afes" v-bind:currency="currency" v-bind:tax="tax" v-bind:company_name="company_name"></InvoiceHeader>
      <div class="mt-2 invoice-status">
        <InvoiceStatus :invoice="invoice" :toggle=false></InvoiceStatus>
      </div>
      <div>
         <b-button v-if="!fromexternal" v-b-modal.master-invoice-modal class="pull-right pl-3 btn-sm font-weight-bold" variant="primary"><span class="mt-2 d-none d-sm-inline mr-1">View Master Invoice </span><i class="fas fa-search"></i></b-button>
      </div>
      <b-row class="mt-3 clearfix mb-4">
        <b-col md="6" lg="4">
          <VendorDetails v-bind:invoice="invoice"></VendorDetails>
        </b-col>
        <b-col md="6" lg="4">
          <CompanyDetails v-bind:invoice="invoice" v-bind:company_name="company_name"></CompanyDetails>
        </b-col>
        <b-col md="6" lg="4">
          <InvoiceDetails v-bind:invoice="invoice" v-bind:highestAfe="highestAfe" v-bind:vwref="vwref"></InvoiceDetails>
        </b-col>
      </b-row>

      <h3 class="d-inline-block">Services</h3><button v-if="invoice.accountant_paid === 0" type="button" class="btn btn-sm btn-danger float-right" @click="removeAll()" name="button"><strong>Remove All AFES <i class="fa fa-times"></i></strong></button>
      <div class="table-responsive-sm">
        <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
        <table class="table wss-invoice-table wss-striped">
          <thead>
            <tr class="service-rows service-table-headers">
              <th @click="sortServices('name')" class="center service-name">Service</th>
              <th @click="sortServices('code')" class="center service-name">Code</th>
              <th @click="sortServices('date')" class="datepickers right">Start Date - End Date</th>
              <th @click="sortServices('uom')" class="uom-select">UOM <i class="fa fa-question-circle mr-1" v-b-tooltip.hover title="Unit Of Measurement"></i></th>
              <th @click="sortServices('price')" class="price center">Price/Unit</th>
               <th @click="sortServices('price')" class="price center">Discounted Price/Unit</th>
              <th @click="sortServices('quantity')" class="price center">Quantity</th>
              <th @click="sortServices('discount')" class="price center">Discount</th>
              <th @click="sortServices('total')" class="right">Total</th>
              <th></th>
            </tr>
          </thead>
          
          <tbody v-for="service in unassigned">
            <tr class="service-rows mt-2 service-bg unassigned rt-wss-create-afe">
              <td class="center price v-align-middle">
                <h4 class="d-xs-block d-xl-none">Service: {{service.name}} {{service.vsid}}</h4>
                <h5 class="mb-0 d-none d-xl-block">{{service.name}}</h5>
              </td>
              <td class="center price v-align-middle">
                <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                <span class="d-none d-xl-block">{{service.type}}</span>
              </td>
              <td class="center price v-align-middle">
                <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
                {{service.start_date | moment("MM/DD/YYYY")}} - {{service.end_date | moment("MM/DD/YYYY")}} <br><span style="font-size: 10px;">({{(parseInt($moment(service.end_date).diff($moment(service.start_date), 'days')) + 1)}} Days)</span>
              </td>
              <td class="left price v-align-middle">
                <h6 class="d-xs-block d-xl-none">Unit Of Measurement: {{service.uom}}</h6>
                <span class="d-none d-xl-block">{{service.uom}}</span>
              </td>
              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.pu)}}</h6>
                <span class="d-none d-xl-block">${{formatPrice(service.pu)}}</span>
              </td>

              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:
                  {{service.discount ?  '$' + _discount_price_per_unit(service.discount,service.pu) : service.name ?  '$' + formatPrice(service.pu) : null }}
                </h6>
                <span class="d-none d-xl-block">
                  {{service.discount ?  '$' + _discount_price_per_unit(service.discount,service.pu) : service.name ?  '$' + formatPrice(service.pu) : null }}
                </span>
              </td>

              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none">Quantity: {{parseFloat(service.quantity)}}</h6>
                <span class="d-none d-xl-inline-block">{{parseFloat(service.quantity)}}</span>
                <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.end_date).diff($moment(service.start_date), 'days') + 1 != service.quantity">
                  <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end dates (' + (parseInt($moment(service.end_date).diff($moment(service.start_date), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                </span>
              </td>
              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none">Discount: {{formatPrice(service.discount)}}%</h6>
                <span class="d-none d-xl-block">{{formatPrice(service.discount)}}%</span>
              </td>
              <td class="right v-align-middle">
                <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</h6>
                <span class="d-none d-xl-block">${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</span>
              </td>
              <td>
                <b-dropdown variant="primary" size="sm" v-if="invoice.accountant_paid === 0" right :disabled="disableEdits == 1" text="Select Action" class="m-md-2 wss-action-dropdown">
                   <b-dropdown-item v-on:click="addNewUnassignedAfeLine(service, service)">AFE <i class="ml-1 fa fa-plus-circle"></i></b-dropdown-item>
                 </b-dropdown>
              </td>
            </tr>
                      <tr :class="{createAfe: service != createdUnassignedServiceLine}" class="rt-wss-create-afe">
                      <td class="p-0 show" colspan="100%">
                        <div class="table-responsive-sm">
                          <table class="table no-bg afenum mb-0">
                            <thead class="rt-border">
                              <tr class="afe-rows">
                                <th class="actions fit"></th>
                                <th class="center"><strong>AFE / PO</strong></th>
                                <th class="center"><strong>% <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></strong></th>
                                <th class="center"><strong>Cost Code Chain</strong></th>
                                <!-- <th class="center"><strong>Cost Code #2 / Minor</strong></th>
                                <th class="center"><strong>Cost Code #3 / Description</strong></th>
                                <th class="center"><strong>Majo/ Minor/ Description-1</strong></th> -->
                                <th class="center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <button type="button" v-on:click="cancelAfe()" v-b-tooltip.hover title="AFE / PO Cancel" class="mt-1 fit btn btn-sm btn-danger"><i class="fa fa-times-circle"></i></button>
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                  <v-select
                                    v-if="service == createdUnassignedServiceLine"
                                    v-model="createAfenum"
                                    label="afenum"
                                    :options="createAfeData"
                                    :value="afenum"
                                    @change="getcc1Create()"
                                  ></v-select>
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                  <input type="number" class="rt-percent" step="any" v-model="percentage">
                                </td>

                    
                    <td>
                  <v-select
                    :options="cc1CreateData"
                    class="mt-2"
                    :filterable="true"
                    :label="`${search_cc}`"
                    v-model="CC_data"
                  >
                    <template #list-footer>
                      <li ref="load" class="loader">
                        Loading more options...
                      </li>
                    </template>
                    <template v-slot:option="option">
                      <span>{{ option.costcode1 }}</span>
                      <span>-{{ option.costcode2 }}</span>
                      <span>-{{ option.costcode3 }}</span>
                    </template>
                    <template #selected-option="{ costcode1, costcode2, costcode3, option, deselect, multiple, disabled }">
                      <span>{{ costcode1 }}</span>
                      <span>-{{ costcode2 }}</span>
                      <span>-{{ costcode3 }}</span>
                    </template>
                  </v-select>
                    </td>
                    <td class="actions">
                      <button type="button" v-on:click="addAfeUnassignedLine(service)" class="btn btn-sm mt-1 btn-success mr-2">Add <i class="fa fa-plus"></i></button>
                      <!-- {{service}} -->
                      <button type="button" v-on:click="addAfeToAllInvoiceServices(service)" class="btn btn-sm mt-1 btn-success">Add To All <i class="fa fa-plus"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-for="(afe, index) in merged">
            <tr class="service-rows mt-2 service-bg rt-wss-create-afe" v-for="(service, serviceIndex) in afe">
              <td class="center price v-align-middle">
                <h4 class="d-xs-block d-xl-none">Service: {{service.name}} {{service.vsid}}</h4>
                <h5 class="mb-0 d-none d-xl-block">{{service.name}}</h5>
              </td>
              <td class="center price v-align-middle">
                <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                <span class="d-none d-xl-block">{{service.type}}</span>
              </td>
              <td class="center price v-align-middle">
                <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
                {{service.start_date | moment("MM/DD/YYYY")}} - {{service.end_date | moment("MM/DD/YYYY")}} <br><span style="font-size: 10px;">({{(parseInt($moment(service.end_date).diff($moment(service.start_date), 'days')) + 1)}} Days)</span>
              </td>
              <td class="left price v-align-middle">
                <h6 class="d-xs-block d-xl-none">Unit Of Measurement: {{service.uom}}</h6>
                <span class="d-none d-xl-block">{{service.uom}}</span>
              </td>
              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.pu)}}</h6>
                <span class="d-none d-xl-block">${{formatPrice(service.pu)}}</span>
              </td>


              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:
                  {{service.discount ?  '$' + _discount_price_per_unit(service.discount,service.pu) : service.name ?  '$' + formatPrice(service.pu) : null }}
                </h6>
                <span class="d-none d-xl-block">
                  {{service.discount ?  '$' + _discount_price_per_unit(service.discount,service.pu) : service.name ?  '$' + formatPrice(service.pu) : null }}
                </span>
              </td>

              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none">Quantity: {{parseFloat(service.quantity)}}</h6>
                <span class="d-none d-xl-inline-block">{{parseFloat(service.quantity)}}</span>
                <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.end_date).diff($moment(service.start_date), 'days') + 1 != service.quantity">
                  <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end dates (' + (parseInt($moment(service.end_date).diff($moment(service.start_date), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                </span>
              </td>
              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none">Discount: {{formatPrice(service.discount)}}%</h6>
                <span class="d-none d-xl-block">{{formatPrice(service.discount)}}%</span>
              </td>
              <td class="right v-align-middle">
                <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</h6>
                <span class="d-none d-xl-block">${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</span>
              </td>
              <td>
                <b-dropdown variant="primary" size="sm" v-if="invoice.accountant_paid === 0" right :disabled="disableEdits == 1" text="Select Action" class="m-md-2 wss-action-dropdown">
                   <b-dropdown-item v-on:click="addNewAfeLine(afe, service)">AFE <i class="ml-1 fa fa-plus-circle"></i></b-dropdown-item>
                   <b-dropdown-item v-on:click="removeAllAfe(service)">Remove All Assigned AFES <i class="ml-1 fa fa-times"></i></b-dropdown-item>
                 </b-dropdown>
              </td>
            </tr>
                      <tr>
                      <td class="p-0" colspan="100%">
                        <div class="table-responsive-sm">
                          <table class="table no-bg afenum mb-0">
                            <thead class="rt-border">
                              <tr class="afe-rows">
                                <th class="actions fit"></th>
                                <th class="center"><strong>AFE / PO</strong></th>
                                <th class="center"><strong> AFE Percentage % <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></strong></th>
                                <th class="center"><strong> Cost Code Chain </strong></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(line, aIndex) in afe[0].afes" :class="{editingAfe: line == editedAfeLine}" class="rt-wss-create-afe rt-mobile-indent">
                                <td class="d-xs-none d-lg-block actions fit border-0"></td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h4 class="d-block d-xl-none">AFE</h4><button :disabled="disableEdits == 1" type="button" v-on:click="removeAllAfe(afe[0].afes)" class="fit btn btn-danger float-right d-inline-block d-xl-none ml-2"><i class="fa fa-minus-circle"></i></button><button @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)" class="btn btn-primary d-inline-block d-xl-none float-right"><i class="fa fa-edit"></i></button>
                                    <i class="fa fa-chevron-circle-right"></i> {{line.afenum}}
                                  </div>
                                  <div class="edit" @click="editAfe = 0">
                                    <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                    <v-select
                                      v-if="line == editedAfeLine"
                                      v-model="line.afenum"
                                      label="afenum"
                                      :options="serviceChainData"
                                      :value="afenum"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    {{line.percentage}}
                                  </div>
                                  <div class="edit">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    <input type="number" class="rt-percent" step="any" v-model="percentage">
                                  </div>
                                </td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                                    {{line.ccone_code }} -- {{line.cctwo_code }} -- {{line.ccthree_code }}
                                  </div>
                                   <div class="edit" @click="editAfe = 0">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                                  <v-select
                                  :options="cc1Data"
                                  v-if="line == editedAfeLine"
                                  class="mt-2"
                                  :filterable="true"
                                  :label="`${search_cc}`"
                                  v-model="CC_data"
                                >
                                  <template #list-footer>
                                    <li ref="load" class="loader">
                                      Loading more options...
                                    </li>
                                  </template>
                                  <template v-slot:option="option">
                                    <span>{{ option.costcode1 }}</span>
                                    <span>-{{ option.costcode2 }}</span>
                                    <span>-{{ option.costcode3 }}</span>
                                  </template>
                                  <template #selected-option="{ costcode1, costcode2, costcode3, option, deselect, multiple, disabled }">
                                    <span>{{ costcode1 }}</span>
                                    <span>-{{ costcode2 }}</span>
                                    <span>-{{ costcode3 }}</span>
                                  </template>
                                </v-select>
                                      </div> 
                                      </td>
                                <td class="actions">
                                  <div class="edit">
                                    <button type="button" v-on:click="editAddAfeToInvoice(line, afe, index, aIndex, afes)" class="btn btn-primary"><i class="fa fa-edit"></i></button>
                                  </div>
                                </td>
                              </tr> 
                              <tr :class="{createAfe: afe != createdServiceLine}" class="rt-wss-create-afe">
                                <td class="show">
                                  <button type="button" v-on:click="cancelAfe()" v-b-tooltip.hover title="AFE Cancel" class="fit btn btn-sm btn-danger"><i class="fa fa-times-circle"></i></button>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                    <v-select
                                      v-if="afe == createdServiceLine"
                                      v-model="createAfenum"
                                      label="afenum"
                                      :options="createAfeData"
                                      :value="afenum"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    <input type="number" class="rt-percent" step="any" v-model="percentage">
                                  </div>
                                </td> 
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                               <v-select
                                :options="cc1CreateData"
                                class="mt-2"
                                :filterable="true"
                                :label="`${search_cc}`"
                                v-model="CC_data"
                               >
                            <template #list-footer>
                              <li ref="load" class="loader">
                                Loading more options...
                              </li>
                            </template>
                            <template v-slot:option="option">
                              <span>{{ option.costcode1 }}</span>
                              <span>-{{ option.costcode2 }}</span>
                              <span>-{{ option.costcode3 }}</span>
                            </template>
                            <template #selected-option="{ costcode1, costcode2, costcode3, option, deselect, multiple, disabled }">
                              <span>{{ costcode1 }}</span>
                              <span>-{{ costcode2 }}</span>
                              <span>-{{ costcode3 }}</span>
                            </template>
                          </v-select>
                                  </div>
                                </td> 

                                <td class="actions">
                                  <div class="show">
                                    <button type="button" v-on:click="addAfeLine(afe, line)" class="btn btn-sm btn-success mr-2">Add <i class="fa fa-plus"></i></button>
                                    <button type="button" v-on:click="addAfeToAllInvoiceServices(index, afe)" class="btn btn-sm btn-success">Add To All <i class="fa fa-plus"></i></button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
          </tbody>
        </table>
      </div>
      <InvoiceTotals v-bind:invoice="invoice" v-bind:serviceData="serviceData" v-bind:currency="currency"></InvoiceTotals>
      <div v-if="set === 1 && invoice.inv_passed !== 17 && invoice.inv_passed !== 9 && invoice.inv_passed !== 5 && invoice.inv_passed !== 14 && invoice.inv_passed !== 5
      && invoice.inv_passed !== 24 && invoice.inv_passed !== 34 && invoice.inv_passed !== 44 && invoice.inv_passed !== 54">
        <h5 v-if="invoice.vendor_note">Comments From Vendor</h5>
        <p v-if="invoice.vendor_note">{{invoice.vendor_note}}</p>
        <h5>Comments <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Comments will be seen by all user levels and be attached on invoice"></i></h5>

        <textarea name="comments" v-model="comments" id="" class="w-100 rounded border mt-2 mb-3" rows="3"></textarea>

        <button v-if="role != 'Accountant'" class="btn btn-danger mr-4 mb-4" @click="submitInvoice(2)"><i class="fa fa-times-circle"></i> Rejects</button>
        <button v-else class="btn btn-danger mr-4 mb-4" @click="rejectInvoice(2)"><i class="fa fa-times-circle"></i> Reject</button>
        <b-dropdown class="mr-4 mb-4" variant="primary" text="Send Back To">
          <b-dropdown-item v-on:click="kickBack(0)">Well Site Supervisor</b-dropdown-item>
        </b-dropdown>
        <button type="submit" @click="submitInvoice(1)" class="btn btn-success mb-4 mr-4"><i class="fa fa-check"></i> Approve</button>
      </div>
      <div v-show="invoiceError" class="d-block">
        <i v-show="invoiceError" class="fa fa-exclamation-triangle"></i><span v-show="invoiceError" class="help">Invoice Error. Please fix invoice or contact admin.</span>
      </div>
    </b-card>
    <b-card>
      <InvoiceHistory v-bind:history="history"></InvoiceHistory>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate';
import moment from 'moment';
import VueResizable from 'vue-resizable'

import VendorDetails from './../invoice/InvoiceVendorDetails'
import CompanyDetails from './../invoice/InvoiceCompanyDetails'
import InvoiceDetails from './../invoice/InvoiceDetails'
import InvoiceHeader from './../invoice/InvoiceHeader'
import InvoiceAfes from './../invoice/InvoiceAfes'
import InvoiceTotals from './../invoice/InvoiceTotals'
import InvoiceHistory from './../invoice/InvoiceHistory'
import InvoiceStatus from './../../components/InvoiceStatus'
import {RotateSquare2} from 'vue-loading-spinner'
import MasterInvoice from './../../components/MasterInvoice'

export default {
  name: 'WTSInvoice',
  components: {
    vSelect,
    Datepicker,
    RotateSquare2,
    InvoiceHeader,
    VendorDetails,
    InvoiceAfes,
    CompanyDetails,
    InvoiceDetails,
    InvoiceTotals,
    InvoiceHistory,
    VueResizable,
    InvoiceStatus,
    'Master-Invoice' : MasterInvoice
  },
  watch: {
    scrollPosition: function (newVal) {
      if(newVal >= 0) {
        this.scrollDown = "scrollUp";
      }
      if(newVal > 70) {
        this.scrollDown = "scrollDown";
      }
    },
  },
  data () {
    return {
      width: 200,
      height: 100,
      today: new Date(),
      uom: [
        { value: 'L', label: 'L' },
        { value: 'KG', label: 'KG' },
      ],
      vendorDetails: [],
      serviceNames: [
        { value: 'Drilling', label: 'Drilling', code: '9294'},
        { value: 'Water', label: 'Water', code: '9594'},
      ],
      allafe: [
        { value: '34353', label: '34353'},
        { value: '65434', label: '65434'},
      ],
      service: {
        startdate: '',
        enddate: '',
        quantity: 1,
        price: 3.99,
        discount: 0,
      },
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: [],
      afesTemp: [],
      afenum: '',
      ccone_code: '',
      cctwo_code: '',
      ccthree_code: '',
      percent: 0,
      vwref: [],
      afeData: [],
      gst: 5,
      quickpay: 0,
      services: [],
      netDays: "",
      history: [],
      serviceData: [],
      comments: "",
      invoiceError: false,
      tax_percentage: 0,
      deleteAps: [],
      serviceChainData: [],
      uwiData: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      cc1: [],
      cc2: [],
      cc3: [],
      //highestAfe: [],
      company_name: '',
      invoice: [],
      currency: '',
      tax: '',
      set: 0,
      servicenameSort: "",
      lineAfe: "",
      beforeEditCache: [],
      beforeEditCacheCatch: 0,
      beforeEditCacheIndex: 0,
      beforeEditAfeCache: null,
      beforeEditAfeCacheCatch: 0,
      beforeEditAfeCacheIndex: 0,
      editedAfeLine: null,
      editAfe: 1,
      update: 0,
      serviceid: 0,
      scrollPosition: null,
      scrollDown: 'scrollUp',
      percentage: 0,
      createdServiceLine: 0,
      createAfenum: [],
      createCC1Percentage: 100,
      createCC1Code: [],
      createCC2Code: [],
      createCC3Code: [],
      createAfeData: [],
      cc1CreateData: [],
      cc2CreateData: [],
      cc3CreateData: [],
      createAfe: 0,
      disableEdits: 0,
      afes: [],
      order: "",
      ascDesc: 'asc',
      toggle: false,
      createdUnassignedServiceLine: [],
      createdServiceLineItem: [],
      editAfeChainLine: [],
      loading: false,
      usersName: "",
      role:'',
      projectTypeData: [{ 'projecttype': 'Resource Project'},{'projecttype': 'Operation Project'}],
      projectType:'',
      CC_data: '',
      search:'',
      search_cc:'costcode1',
      vendorId:0,
      toggle_edit_afe: true,
      toggle_add_afe_line: false,
      currentAfeNum:'',
      invoice_vendor_mail: ''
    }
  },
  props : ['dontRedirect','fromexternal'],
  methods: {
    kickBack(pass){
      let data = JSON.stringify({
        "invnum": this.invoice.invnum,
        "inv_pass": pass
      })
      this.$http.post('/wts/edit/invpass_kickback', data)
        .then(() => {
           this.$toasted.show('Invoice Sent Back Successfully', {type: 'success', duration: '3000'});
            if(role != 'Accontant') {
               this.$router.push('/pm');
            } else {
               this.$router.push('/accountant');
           }
         })
         .catch(() => {})
    },
    removeAll(){
      this.loading = true;
      this.afes.forEach(v=> {
      let data = { id: v.apid, service_ccid: v.service_ccid, projectid: this.invoice.projectid }
        if(this.fake_vendor){
          data.fake_vid = this.fake_vendor.vid
        }
        this.$axios.post('/wss/delete/service_ap', data)
        .then(response => {
          this.afes = [];
          this.createdServiceLine = [];
          this.createdUnassignedServiceLine = [];
          this.disableEdits = 0;
        })
        .catch(error => {})
      });
      this.loading = false;
    },
    sortServices(sort) {
      // this.toggle = !this.toggle
      //
      // if(this.toggle){
      //   this.ascDesc = "asc"
      // }
      // else {
      //   this.ascDesc = "desc";
      // }
      //
      // if(sort == "name"){
      //   this.order = "name";
      // }
      // if(sort == "type"){
      //   this.order = "type";
      // }
      // if(sort == "date"){
      //   this.order = "start_date";
      // }
      // if(sort == "uom"){
      //   this.order = "uom";
      // }
      // if(sort == "price"){
      //   this.order = "pu";
      // }
      // if(sort == "quantity"){
      //   this.order = "quantity";
      // }
      // if(sort == "discount"){
      //   this.order = "discount";
      // }
      // if(sort == "total"){
      //   this.order = "total";
      // }
    },
    editAfeLine (afe, serviceIndex, afeIndex, afes, service) {
      if(this.invoice.accountant_paid === 1){
        return
      }
      if(this.disableEdits == 1){
        this.$toasted.show('Please save edit first.', {type: 'error', duration: '3000'})
        return
      }
      if(service.length > 1){
        this.$toasted.show('Cannot edit grouped cost code chains.', {type: 'error', duration: '3000'})
        return
      }
      this.editAfeChainLine = {
        afe_num: afe.afenum,
        ccone_code: afe.ccone_code,
        cctwo_code: afe.cctwo_code,
        ccthree_code: afe.ccthree_code,
        percentage: afe.percentage,
      };
      this.editAfe = 1;

   this.$axios.get("/wss/get/service_chains/" + this.invoice.companyid + "/" + this.invoice.projectid + "/" + service[0].serviceid + "/" + 1)
        .then(response => {
          if(response.data.result.length > 0){
      
            this.editedAfeLine = afe;
            this.percentage = afe.percentage;
            this.disableEdits = 1;
            var result = this.lodash.groupBy(response.data.result, 'afenum');
            this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            
            var currentAfe = this.serviceChainData.findIndex(x=>x.afenum == afe.afenum)
            this.merged[serviceIndex][0].afes[afeIndex].afenum = this.serviceChainData[currentAfe];
            this.editAfe = 1;
          }
          else {
            this.editedAfeLine = [];
            this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
            return
          }
          this.cc1Data = response.data.cost_code_chain_data;
          var cc1Index = this.cc1Data.findIndex(x=>x.costcode1 == afe.ccone_code && x.costcode2 == afe.cctwo_code && x.costcode3 == afe.ccthree_code)
          this.CC_data = this.cc1Data[cc1Index];
          this.editAfe = 1;

        })
        .catch(err=> {});
    },
    async editAddAfeToInvoice(line, service, serviceIndex, afeIndex, afes){
       let cc_num = this.CC_data;
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }
        if(service[0].afes.length > 0) {
        for(let [i, v] of Object.entries(service[0].afes)) {
          if(i != afeIndex) {
          if(v.afenum == line.afenum.afenum && v.ccone_code == cc_num.costcode1 && v.cctwo_code == cc_num.costcode2  && v.ccthree_code == cc_num.costcode3) {
              this.$toasted.show('Duplicates Chain', {type: 'error', duration: '3000'})
                return 
            }
          }
        }
      }
      const  { ccthree_code, ccone_code, afe_num, cctwo_code } = this.editAfeChainLine;
      let find = this.afes.findIndex(x => ( x.vsid == service[0].vsid && x.ccthree_code == ccthree_code && x.ccone_code == ccone_code && x.cctwo_code == cctwo_code && x.afenum == afe_num));
      let deleteAfeData = {
            id: this.afes[find].apid,
            service_ccid: this.afes[find].service_ccid,
            projectid: this.invoice.projectid
      }
      const deleteAfe = await this.$axios.post('/wss/delete/service_ap', JSON.stringify(deleteAfeData));
      this.addAfeUnassignedLine(service[0], line, find); 
    },
    addNewUnassignedAfeLine(afe, service){
      this.$http.get("/wss/get/service_chains/" + this.invoice.companyid + "/" + this.invoice.projectid + "/" + service.serviceid + "/" + 1)
      .then(response => {
        if(response.data.result.length > 0){
            this.createdUnassignedServiceLine = afe;
            this.percentage = 100;
            var result = this.lodash.groupBy(response.data.result, 'afenum');
            this.createAfeData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            this.createAfenum = this.createAfeData[0];
            this.createAfe = 1;
            this.disableEdits = 1;
            this.createAfeData[0];
          }
          else {
            this.createdServiceLine = 0;
            this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
            return
          }
          this.cc1CreateData = response.data.cost_code_chain_data;
          this.CC_data = this.cc1CreateData[0];
          this.createAfe = 1;

        })
        .catch(err=> {}); 
    },
    addNewAfeLine(afe, service){
    this.$axios.get("/wss/get/service_chains/" + this.invoice.companyid + "/" + this.invoice.projectid + "/" + service.serviceid + "/" + 1)
        .then(response => {
          if(response.data.result.length > 0){
            this.toggle_add_afe_line = true;
            this.createdServiceLine = afe;
            this.createdServiceLineItem = service;
            this.percentage = 100;
            var result = this.lodash.groupBy(response.data.result, 'afenum')
            this.createAfeData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            this.createAfenum = this.createAfeData[0];
            this.createAfe = 1;
            this.disableEdits = 1;
          }
          else {
            this.createdServiceLine = 0;
            this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
            return
          }
          this.cc1CreateData = response.data.cost_code_chain_data;
          this.CC_data= this.cc1CreateData[0];
          this.createAfe = 1;
        })
        .catch(error => {
          console.log(error)
        });
    },
    getcc1(serviceIndex, afeIndex) {
      if(this.editAfe == 0 && this.merged[serviceIndex][0].afes[afeIndex].afenum.items){
        var cc1Temp = this.lodash.groupBy(this.merged[serviceIndex][0].afes[afeIndex].afenum.items, 'ccone_code');
        this.cc1Data = Object.keys(cc1Temp).map(key => ({ ccone_code: key, items: cc1Temp[key] }));
        this.merged[serviceIndex][0].afes[afeIndex].ccone_code = this.cc1Data[0];
      }
    },
    getcc2(serviceIndex, afeIndex) {
      if(this.editAfe == 0 && this.merged[serviceIndex][0].afes[afeIndex].ccone_code.items){
        var cc2Temp = this.lodash.groupBy(this.merged[serviceIndex][0].afes[afeIndex].ccone_code.items, 'cctwo_code');
        this.cc2Data = Object.keys(cc2Temp).map(key => ({ cctwo_code: key, items: cc2Temp[key] }));
        this.merged[serviceIndex][0].afes[afeIndex].cctwo_code = this.cc2Data[0];
      }
    },
    getcc3(serviceIndex, afeIndex) {
      if(this.editAfe == 0 && this.merged[serviceIndex][0].afes[afeIndex].cctwo_code.items){
        var cc3Data = this.merged[serviceIndex][0].afes[afeIndex].cctwo_code.items.filter(o => !o.ccthree_code.includes('---'));
        var cc3Temp = this.lodash.groupBy(cc3Data, 'ccthree_code');
        this.cc3Data = Object.keys(cc3Temp).map(key => ({ ccthree_code: key, items: cc3Temp[key] }));
        this.merged[serviceIndex][0].afes[afeIndex].ccthree_code = this.cc3Data[0];
      }
    },
    getcc1Create(serviceIndex) {
      if(this.createAfe == 0 && this.createAfenum.items){
        var cc1Temp = this.lodash.groupBy(this.createAfenum.items, 'ccone_code');
        this.cc1CreateData = Object.keys(cc1Temp).map(key => ({ ccone_code: key, items: cc1Temp[key] }));
        this.createCC1Code = this.cc1CreateData[0];
      }
    },
    getcc2Create(serviceIndex) {
      if(this.createAfe == 0 && this.createCC1Code.items){
        var cc2Temp = this.lodash.groupBy(this.createCC1Code.items, 'cctwo_code');
        this.cc2CreateData = Object.keys(cc2Temp).map(key => ({ cctwo_code: key, items: cc2Temp[key] }));
        this.createCC2Code = this.cc2CreateData[0];
      }
    },
    getcc3Create(serviceIndex) {
      if(this.createAfe == 0 && this.createCC2Code.items){
        var cc3Data = this.createCC2Code.items.filter(o => !o.ccthree_code.includes('---'));
        var cc3Temp = this.lodash.groupBy(cc3Data, 'ccthree_code');
        this.cc3CreateData = Object.keys(cc3Temp).map(key => ({ ccthree_code: key, items: cc3Temp[key] }));
        this.createCC3Code = this.cc3CreateData[0];
      }
    },
    addAfeUnassignedLine(service, line, find) {
      let data = {};
      var cc_num = [];
      cc_num = this.CC_data;
      cc_num['afenum'] = line == undefined ? this.createAfenum.afenum : line.afenum.afenum;
      
      if(this.percentage > 100 || this.percentage <= 0){
          this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
          return
        } 

     if(service.afes.length > 0) {
        service.afes.forEach(v=> {
          if(line == undefined) {
            if(v.afenum == this.createAfenum.afenum && v.ccone_code == cc_num.costcode1 && v.cctwo_code == cc_num.costcode2 && v.ccthree_code == cc_num.costcode3) {
            this.$toasted.show('Duplicates Chain', {type: 'error', duration: '3000'})
            return 
          }
        }
      })   
    }
      let service_data = this.serviceData.filter((x, i) => this.serviceData[i].vsid == service.vsid);
      let send = JSON.stringify({
        serviceids: service_data,
        pwcaid:  line == undefined ?  this.createAfenum.items[0].pwcaid : line.afenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        projectType : 1,
       companyid: this.invoice.companyid,
        projectid: this.invoice.projectid,
        vid: this.invoice.vid
      });

      this.AddServiceToAFe(send, service, cc_num, line, find);

    if(this.fake_vendor){
        data.fake_vid = this.fake_vendor.vid
      }

    },

        AddServiceToAFe(send, service, cc_num, line, find) {
          this.$http
          .post("/wts/create/costcode_and_assign_service", send)
          .then((response) => {
            if (response.status === 200) {
              this.$toasted.show("Successful Import.", {
                type: "success",
                duration: "3000",
              });
          response.data.addedServices.forEach(v=> {    
              let data = {
                id: v.vsid,
                percentage: this.percentage,
                projectid: this.invoice.projectid,
                ccone_code: cc_num.costcode1,
                cctwo_code: cc_num.costcode2,
                ccthree_code: cc_num.costcode3,
                service_ccid : v.service_ccid
              }
              this.AddAfe( service, data, line, find);
          });
            
        }
      })
      .catch((error) => {
         this.$toasted.show("Error Adding cost Code", { type: "error", duration: "3000" });
      })
    },

     AddAfe(service, data, line, find) {
      this.$axios.post('/wss/create/service_ap', data)
      .then(response => {
      if(line !== undefined) { 
        Vue.set(this.afes, find, {
          afenum: line.afenum.afenum,
          ccone_code: data.ccone_code,
          cctwo_code: data.cctwo_code,
          ccthree_code: data.ccthree_code,
          percentage: this.percentage,
          service_ccid: data.service_ccid,
          apid: response.data.apid,
          vsid: data.id
        });
        
      }   else {

        this.afes.push({
          vsid: data.id,
          afenum: this.createAfenum.afenum,
          ccone_code:  data.ccone_code,
          cctwo_code: data.cctwo_code,
          ccthree_code: data.ccthree_code,
          percentage: this.percentage,
          service_ccid: data.service_ccid,
          apid: response.data.apid
        })

      }
        this.createdUnassignedServiceLine = 0;
        this.disableEdits = 0;
      })
      .catch(error => {
        console.log(error,"error-2");
        this.$toasted.show('Duplicate AFE', {type: 'error', duration: '3000'})
      })
    },
   addAfeLine(afes, line) {
   if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }
      this.addAfeUnassignedLine(afes[0]);
    },
    async addAfeToAllInvoiceServices() {
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }

  let cc_num = this.CC_data;
  cc_num['afenum'] = this.createAfenum.afenum;
    if(this.afes.filter(e => e.afenum === cc_num.afenum).length > 0){
        this.$toasted.show('AFE Duplicate For a line item', {type: 'error', duration: '3000'})
        return;
      }

     let send = JSON.stringify({
        serviceids: this.serviceData,
        projectid: this.invoice.projectid,
        pwcaid: this.createAfenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        companyid: this.invoice.companyid,
        projectType : 1,
        vid: this.invoice.vid
      });
         await this.AddServiceToAFe(send, this.serviceData, cc_num);

      this.createdServiceLine = 0;
      this.disableEdits = 0;
      this.lineLoading = true;
      for (var i = 0; i < this.serviceData.length; i++) {
        var service = this.serviceData[i];
        const tryImportAfe = await this.tryImportAfe(service, cc_num);
        console.log(tryImportAfe, "try import Afe data");
      };

      this.lineLoading = false;
    },
    getChain(serviceid){
      return this.$http.get("/wss/get/service_chains/" + this.invoice.companyid + "/" + this.invoice.projectid + "/" + serviceid)
      .then(response => {
        if(response.data.result.length > 0){
          return response.data.result;
        }
      }).catch(error => {
        console.log(JSON.stringify(error))
      })
    },
    async tryImportAfe(service, cc_num){
      const getChain = await this.getChain(service.serviceid);
      var match = [];

      if(getChain){
        if(this.createCC3Code !== undefined && this.createCC3Code !== null){
          match = getChain.filter(x=>x.afenum == cc_num.afenum && x.ccone_code == cc_num.ccone_code && x.cctwo_code == cc_num.cctwo_code && x.ccthree_code == cc_num.ccthree_code && x.serviceid == service.serviceid)
        }
        else {
          match = getChain.filter(x=>x.afenum == cc_num.afenum && x.ccone_code == cc_num.ccone_code && x.cctwo_code == cc_num.cctwo_code && x.serviceid == service.serviceid)
        }

        if(match.length > 0){
          let data = JSON.stringify({
            id: service.vsid,
            projectid: this.invoice.projectid,
            service_ccid: match[0].service_ccid,
            percentage: this.percentage,
          })

          this.$axios.post('/wss/create/service_ap', data)
          .then(response => {
            this.afes.push({
              vsid: service.vsid,
              afenum: match[0].afenum,
              ccone_code: match[0].ccone_code,
              cctwo_code: match[0].cctwo_code,
              ccthree_code: match[0].ccthree_code,
              percentage: this.percentage,
              service_ccid: match[0].service_ccid,
              apid: response.data.apid,
              uwi: cc_num.uwi,
              uwi_2: cc_num.uwi_2,
              uwi_3: cc_num.uwi_3,
              lsdsurface: cc_num.lsdsurface,
              lsdbottom: cc_num.lsdbottom,
              ln: cc_num.ln
            })
            this.disableEdits = 0;
          }).catch(error => {
            console.log(JSON.stringify(error))
          })
        }
        else {
          this.disableEdits = 0;
        }
      }
      else {
        this.disableEdits = 0;
      }
    },
    removeAfe: function (cc_num) {
      var filtered = this.afes.filter(x => x.afenum == cc_num.afenum && x.ccone_code == cc_num.ccone_code && x.cctwo_code == cc_num.cctwo_code && x.ccthree_code == cc_num.ccthree_code )
      for (var i = 0; i < filtered.length; i++) {
        let data = JSON.stringify({
          id: filtered[i].apid,
          projectid: this.invoice.projectid,
        })
        var apid = filtered[i].apid;

        this.$axios.post('/wss/delete/service_ap', data)
        .then(response => {
          var findIndex = this.afes.findIndex(x=>x.apid == apid)
          console.log(findIndex);
          Vue.delete(this.afes, findIndex)
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
    removeAllAfe: function (service) {
      this.afes.forEach((v, i) => {
        if(v.vsid == service.vsid) {
          let data = { id: v.apid, projectid: this.invoice.projectid, service_ccid: v.service_ccid }
        if(this.fake_vendor){
          data.fake_vid = this.fake_vendor.vid
        }
        this.$axios.post('/wss/delete/service_ap', data)
        .then(() => {
          this.afes = this.afes.filter(x => x.vsid !== service.vsid);
          this.editAfe = 0;
          this.createAfe = 0;
          this.disableEdits = 0;
          this.createdServiceLine = [];
          this.createdUnassignedServiceLine = [];
        })
        .catch(() => {})
        }
     });
    },
    cancelAfe: function () {
      this.editAfe = 0;
      this.createAfe = 0;
      this.disableEdits = 0;
      this.createdServiceLine = [];
      this.createdUnassignedServiceLine = [];
    },
    rejectInvoice(status) {

      var comments = this.usersName + " ($" + this.formatPrice(this.invoice.total) + ")";
      if(this.comments != ""){
        comments += ": " + this.comments
      } else {
         this.$toasted.show('Please add comment for rejected invoice', {type: 'error', duration: '3000'})
        return
      }

        let data = JSON.stringify({
        invoiceid: this.invoice.invoiceid,
        projectid: this.invoice.projectid,
        ac_note: comments,
        wts_stat: status,
        email: this.invoice_vendor_mail,
        comments: comments,
        usersName: this.usersName,
        invnum: this.invoice.invnum,
        fname: this.invoice.vfname,
        lname: this.invoice.vlname
       });

       this.$http.get('/wts/remove/qbyte_invoice/' + this.invoice.invoiceid)
          .then(res => {
            if(res.data.status != null) {
                 this.$http.post('/accountant/edit/invoice', data)
                  .then(response => {
                      this.$toasted.show('Invoice Updated Successfully', {type: 'success', duration: '3000'});
                      this.$router.push('/accountant');
                  })
                  .catch(error => {
                    this.invoiceError = true;
                  })
              } else {
                 this.$toasted.show('Invoice Is Already Processed in qbyte', {type: 'error', duration: '5000'});     
              }

            }).catch(() => {
               this.$toasted.show('Error', {type: 'error', duration: '5000'});
            });
    },
    submitInvoice(status) {
      if(status === 1 && this.afes.length === 0){
        this.$toasted.show('AFE Table Not Complete.', {type: 'error', duration: '3000'})
        return;
      }

      if(status === 1){
        for (var i = 0; i < this.serviceData.length; i++) {
          var temp = this.afes.filter(x=>x.vsid == this.serviceData[i].vsid);
          if(temp.length == 0){
            this.$toasted.show('AFE Table Not Complete. ' + this.serviceData[i].name + ' does not have AFE assigned.', {type: 'error', duration: '3000'})
            return
          }
        }
      }

      if(this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save service', {type: 'error', duration: '3000'})
        return
      }

      var comments = this.usersName + " ($" + this.formatPrice(this.invoice.total) + ")";
      if(this.comments != ""){
        comments += ": " + this.comments;
      } else if (status === 2){
        this.$toasted.show('Please put comment before rejecting invoice.', {type: 'error', duration: '3000'})
        return
      }

      let data = JSON.stringify({
        invoiceid: this.invoice.invoiceid,
        projectid: this.invoice.projectid,
        wts_stat: status,
        wts_note: comments,
        role:this.role,
        email:this.invoice_vendor_mail,
        comments: comments,
        usersName: this.usersName,
        invnum: this.invoice.invnum,
        fname: this.invoice.vfname,
        lname: this.invoice.vlname
      });

      this.$http.post('/wts/edit/invoice', data)
      .then(response => {
        if (response.status != 200) {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
        this.error = true;
      }
        this.$toasted.show('Invoice Submitted Successfully', {type: 'success', duration: '3000'});
        if(this.dontRedirect){
          this.$emit('onStatusUpdate',  this.invoice.invoiceid);
         } else {
        if(this.role != 'Accountant') {
          this.$router.push('/pm');
        } else {
          this.$router.push('/accountant');
        }
        }
        this.set = this.$session.set('set', 0);
      })
      .catch(error => {
        this.invoiceError = true;
      });
    },
    loadUwis(data){
      var pid = this.invoice.projectid;
      var cid = this.invoice.companyid;

      for (var i = 0; i < data.length; i++) {
        var id = data[i].serviceid;
        console.log(data[i].serviceid);
        this.$axios.get("/wss/get/service_chains/" + cid + "/" + pid + "/" + id)
        .then(response => {
          this.uwiData = this.uwiData.concat(response.data.result);
          if(i === this.serviceData.length){
            this.uwiData = this.uwiData.filter(x=>x.uwi === this.invoice.vwref)

            this.vwref = this.uwiData[0];
          }
        })
        .catch(error => {
        })
      }
    },
    loadData() {
      var pid = this.invoice.projectid;
      var invid = this.invoice.invoiceid;

      this.$http.get('/wss/get/invoice_services_chain/' + pid + '/' + invid)
      .then(response => {
        if(response.data.result.length > 0){

          var temp = response.data.result;
          this.invoice_vendor_mail = temp[0].email;
          for (var i = 0; i < temp.length; i++) {
            var index = this.serviceData.findIndex(x=>x.vsid === temp[i].vsid);
            this.afes.push({
              name: temp[i].name,
              start_date: temp[i].start_date,
              serviceid: temp[i].serviceid,
              end_date: temp[i].end_date,
              vsid: temp[i].vsid,
              afenum: temp[i].afenum,
              ccone_code: temp[i].ccone_code,
              cctwo_code: temp[i].cctwo_code,
              ccthree_code: temp[i].ccthree_code,
              percentage: temp[i].percentage,
              service_ccid: temp[i].service_ccid,
              apid: temp[i].apid,
              uwi: temp[i].uwi,
              uwi_2: temp[i].uwi_2,
              uwi_3: temp[i].uwi_3,
              lsdsurface: temp[i].lsdsurface,
              lsdbottom: temp[i].lsdbottom,
              ln: temp[i].ln
            })
          }
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },
  computed: {
    highestAfe: function() {
      if(this.afes.length > 0){
        var res = Math.max.apply(Math,this.afes.map(function(o){return o.percentage;}))
        return this.afes.find(function(o){ return o.percentage == res; })
      }
    },
    subtotal: function(){
      return this.serviceData.reduce(function(total, item){
        return total + (item.quantity * item.pu) - ((item.discount/100) * item.quantity * item.pu);
      },0);
    },
    unassigned: function(){
      var unassigned = [];
      for (var i = 0; i <  this.serviceData.length; i++) {
        var filtered = this.afes.filter(x=>x.vsid == this.serviceData[i].vsid)
        if(filtered.length == 0) {
          unassigned.push(this.serviceData[i])
        }
      }
      return unassigned
    },
    merged: function(){
      var dict = {};

      this.serviceData.forEach(service => {
        var filtered = this.afes.filter(x=>x.vsid == service.vsid)
        service.afes = [];
        filtered.forEach(afe => {
          service.afes.push({
            afenum: afe.afenum,
            ccone_code: afe.ccone_code,
            cctwo_code: afe.cctwo_code,
            ccthree_code: afe.ccthree_code,
            percentage: afe.percentage,
          })
        })
      })

      this.serviceData.forEach(service => {
        if(service.afes.length > 0){
          dict[JSON.stringify(service)] = []
        }
      });

      this.serviceData.forEach(service => {
        service.afes.forEach(afe => {
            dict[JSON.stringify(service)] = dict[JSON.stringify(service)].concat(afe);
        });
      })

      var dictAfe = {};

      for (var service in dict) {
        let serviceValue = dict[service]
        let key = JSON.stringify(dict[service]);
        if(!(key in dictAfe)){
          dictAfe[key] = [JSON.parse(service)];
        } else {
          dictAfe[key] = dictAfe[key].concat(JSON.parse(service));
        }
      }

      var temp = []

      for (var service in dictAfe) {
        temp.push(dictAfe[service]);
      }
      return temp;
    },
  },
  mounted(){
    window.addEventListener('scroll', this.updateScroll);

    if(!this.$route.params.invoice){
      this.invoice = this.$session.get('invoice');
      this.set = this.$session.get('set');
      this.role = this.$session.get('role');
    }
    else {
      this.invoice = this.$route.params.invoice;
      this.set = this.$route.params.set;
      this.role = this.$route.params.role;
    }

    this.invoiceID = this.invoice.invoiceid;
    this.quickpay = this.invoice.discount;
    this.gst = this.invoice.tax_percentage;
    this.history = this.invoice;
    var a = this.$moment(this.invoice.endtday);
    var b = this.$moment(this.invoice.creation_date);
    this.netDays = a.diff(b, 'days')

    var companyid = this.invoice.companyid;
    var pid = this.invoice.projectid;
    var invid = this.invoice.invoiceid;

    this.$http.get('/wts/get/unassigned_services/' + pid + '/' + invid)
    .then(response => {
      this.serviceData = this.lodash.sortBy(response.data.result, 'name');
      var data = this.serviceData
      this.loadUwis(data);
    })
    .catch(error => {
      this.$toasted.show('Error.', {type: 'error', duration: '3000'})
    })

    this.$http.get('/wts/get/profile')
    .then(response => {
      this.company_name = response.data.company;
      this.usersName = response.data.fname + " " + response.data.lname;
    })
    .catch(error => {
      this.$toasted.show('Error.', {type: 'error', duration: '3000'})
    })

    this.loadData();
  }
}

</script>
